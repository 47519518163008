import React from "react";
//import logo from "./logo.svg";
//import Provider from "./Components/Context/index.js";
import Provider2 from "./Components/Contexttags/index.js";
//import Provider3 from "./Components/Contextimages/index.js";
import Provider4 from "./Components/Contextentags/index.js";
//import Provider4 from "./Components/AmazonPAAPI/index.js";
import "./App.css";
//import SyntaxHighlighter from 'react-syntax-highlighter';
//import Dark from 'react-syntax-highlighter/dist/esm/styles/prism/atom-dark.js';
//import { InView, useInView } from 'react-intersection-observer';
//{useEffect, useState, useRef, useCallback}
//import {
//BrowserRouter as Router,
//Switch,
//Route,
//useParams
//} from "react-router-dom";
import Menu from "./Menu";

//import { render } from '@testing-library/react';

//function App() {
//class App extends React.Component {

//export default Component;


function App() {
	/*constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      posts: []
    };
  }*/
	/*
    //fetch("http://localhost:8080/api/posts/")
     //   .then(res => res.text())
      //  .then(res => this.setState({ apiResponse: res }));
      fetch("http://localhost:8080/api/posts/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            posts: result
          });
        },
        // Remarque : il est important de traiter les erreurs ici
        // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
        // des exceptions provenant de réels bugs du composant.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )*/
	//}

	//componentDidMount() {
	//this.callAPIposts();
	//}

	/*render() {
    const { error, isLoaded, posts } = this.state;
    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Chargement…</div>;
    } else {
      return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload.
              <ul>
              {posts.map(item => (
                <li key={item.id}>
                  {item.content}
                </li>
              ))}
            </ul>
            </p>
          </header>
        </div>
      );
    }
  }*/
	/*function Post() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { slug } = useParams();
    //console.log(props)
    return (
      <div>
        <h3>ID: {slug}</h3>
      </div>
    );
  }*/

	//{`${inView}`}

	//if (error) {
	//  return <div>Erreur : {error.message}</div>;
	//} else if (!isLoaded) {
	//  return <div>Chargement...</div>;
	//} else {
	//<img src={logo} className="App-logo" alt="logo" />
	return (
		<div className="App">
			<Provider2><Provider4>
				<Menu />
			</Provider4></Provider2>
		</div>
	);
	//}
}

export default App;