import React, { useState, useEffect } from "react";

export const TagsContext = React.createContext();

export default function Provider(props) {
	//const [data, setData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [posts, setPosts] = useState([]);
const [error, setError] = useState([]);

	useEffect(() => {
		//fetch("http://localhost:8080/api/posts/")
		fetch("https://www.espace-bien-etre.fr/wp/wp-json/wp/v2/categorie")
			.then((res) => res.json())
			.then(
				(result) => {
					setIsLoaded(true);
					setPosts(result);
//console.log(result);
//console.log(result);
					//console.log(posts);
				},
				// Remarque : il faut gérer les erreurs ici plutôt que dans
				// un bloc catch() afin que nous n’avalions pas les exceptions
				// dues à de véritables bugs dans les composants.
			)
.catch(err => {console.log(err)})
				//(error) => {
				//	setIsLoaded(true);
				//	setError(error);
				//}
			//);
			//);
	}, []);

	return (
		<TagsContext.Provider value={posts}>
			{props.children}
		</TagsContext.Provider>
	);
}
