import React, {
	useEffect,
	useState,
	//useRef,
	//useCallback,
	//useContext,
	Suspense, lazy
} from "react";
import {Helmet} from "react-helmet";
//import { PostsContext } from "./Components/Context";

//import { ImagesContext } from "./Components/Contextimages";
//import { AmazonPAAPShareButtontext } from "./Components/AmazonPAAPI";
import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	useParams,
} from "react-router-dom";
//import JSX_withParallax from './components/ParallaxJSXWrapper.js';
//import { Parallax } from "react-parallax";
import ScrollToTop from "./Components/ScrollToTop.js";
//import WpComments from "react-wordpress-comments";
import CookieNotice from '@shamaz332/react-gdpr';
import ReactGA from "react-ga4";
import loadable from "@loadable/component";
import { PrerenderedComponent } from "react-prerendered-component";
import { useLocation } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { Outlet  } from "react-router-dom";
const prerenderedLoadable = dynamicImport => {
  const LoadableComponent = loadable(dynamicImport);
const Button = React.memo(props => (

    // you can use the `.preload()` method from react-loadable or react-imported-component`
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ))
Button.displayName = 'Button';
  return Button
};
//const Post = prerenderedLoadable(() => import("./MyComponent"));
const Post = prerenderedLoadable(() => import('./routes/Post'));
const Home = prerenderedLoadable(() => import('./routes/Home'));
const Tag = prerenderedLoadable(() => import('./routes/Tag'));

//const AsyncLoadedComponent = loadable(() => import('./deferredComponent'));
//import moment from 'moment/min/moment-with-locales';
//import Moment from 'react-moment';
//import ReactPaginate from 'react-paginate';
/*export function useIsPrerendering() {
  const [isPrerender, setIsPrerender] = useState(true);
  useEffect(() => {
    if (!isPrerendering) {
      setIsPrerender(false);
    }
  }, []);

  return isPrerender;
}*/
//import {sampleSearchItemsApi} from "./sampleSearchItemsApi.js"
//import SyntaxHighlighter from "react-syntax-highlighter";
//import Dark from "react-syntax-highlighter/dist/esm/styles/prism/atom-dark.js";
//import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

//import { InView, useInView } from "react-intersection-observer";
/*
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,,
} from "react-share";*/
/*
import {
  EmailShareButton,
  FacebookShareButton,
  //FacebookMessengerShareButton,
  LinkedinShareButton,
  //PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  //FacebookMessengerIcon,
  LinkedinIcon,
  //PinterestIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
*/
//import Example from "./components/Test.js";

/*
const styles = {
	obj: {
		margin: 0,
		padding: 0,
		height: '120%',
		width: '120%',
		position: 'absolute',
		top: '-20%',
		left: '0%',
		//transform: 'translate(-50%, -50%)',
		borderRadius: '0%',
		background: "URL('https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1986,c_fill,g_auto,h_1117,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F210819134112-09-afro-latinx-childrens-books.jpg') 0 0 no-repeat",
	}
}*/
//const FirstOrb = JSX_withParallax(<div style={styles.obj}></div>, 0.05);
//const SecondOrb = JSX_withParallax(<div style={{ ...styles.obj, top: '0%', left: '80%', backgroundColor: 'red' }}></div>, 0.02)
//<FirstOrb />

const Header = (props) => {
	const { location } = props;
	//console.log(props.match.params);
	//let pathname = location.pathname;
	//let slug = pathname.split("/")
	//console.log(slug[1]);
	//const { slug } = useParams();
/*console.log("header");
	console.log(slug);*/
};

export default function Menu() {
	//const [isLoaded, setIsLoaded] = useState(false);
	//const commonProps = { myProp1: "prop1", myProp2: "prop2" };
//const { slug } = useParams();
const [visible, setVisible] = useState(true);
  const handleClick = () => {
    setVisible(!visible);
  }
	let mentions_title = "";
if (process.env.REACT_APP_LANG_MAIN === 'en') {
mentions_title = "Legal notices and Privacy policy";
} else {
mentions_title = "Mentions légales et Politique de confidentialité";
}

/*const placeholder = "Loading...";
  const isPrerendering = useIsPrerendering();
  if (isPrerendering) {
    return placeholder;
  }*/

	return (
		<BrowserRouter>
			<>
				

				{
					//<Example></Example>
					/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. 
<Route path="/sitemap.xml"
						children={<Sitemap />}
					></Route>

<Route exact path="/pages/:slug/"
						component={Page}
					></Route>
*/
				}
				<ScrollToTop />
				<Switch><Route exact strict path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`}/>} />
					<Route exact path="/mentions-legales-et-politique-de-confidentialite/"
						component={Mentions}
					></Route>
					<Route
						exact path="/blog/:slug/"
						component={Post}
					></Route>
					<Route exact path="/tag/:slug/"
						component={Tag}
					></Route>
					<Route exact path="/page/:id/">
						<Home />
					</Route>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path='*' component={NotFound} status={404}/>
				</Switch>
				<nav>
					<ul className="main">
						<li>
							<Link to="/">&lsaquo; {process.env.REACT_APP_BACK}</Link>
						</li>
						<li>
							<Link to="/mentions-legales-et-politique-de-confidentialite/">{mentions_title}</Link>
						</li>
						<li className="fakelink" onClick={handleClick}>Cookies
						</li>
						{//<li>
							//<Link to="/blog/30">Test</Link>
						//</li>
						}
					</ul>
<CookieNotice
    onSave={(cookies) => {
        //console.log(cookies);

{cookies.map((item) =>
item.name === "marketing" && item.checked == true ? (
//console.log(true),
ReactGA.initialize(process.env.REACT_APP_ANALYTICS)
):("")
)}

    }}
    onInit={(cookies) => {
        //console.log(cookies);

{cookies.map((item) =>
item.name === "marketing" && item.checked == true ? (
//console.log(true),
ReactGA.initialize(process.env.REACT_APP_ANALYTICS)
):("")
)}

//CookieNotice.toggleVisibility=true;

    }}
    //acceptAllButtonText="Accept"
    cookiePrefix="my-cookie-"
    cookies={[
        { name: 'necessary', checked: true, editable: false, default: true, title: process.env.REACT_APP_COOKIE_ESSENTIAL, text: process.env.REACT_APP_COOKIE_ESSENTIAL_TEXT },
        { name: 'marketing', checked: true, editable: true, default: false, title: process.env.REACT_APP_COOKIE_MARKETING, text: process.env.REACT_APP_COOKIE_MARKETING_TEXT }
        //{ name: 'test', checked: false, editable: true, title: 'Title', text: 'Lorem ipsum dolor sit amet.' }
    ]}
toggleVisibility={visible}
preferencesButtonText={process.env.REACT_APP_COOKIE_PREFERENCES}
savePreferencesButtonText={process.env.REACT_APP_COOKIE_SAVE_PREFERENCES}
acceptAllButtonText={process.env.REACT_APP_COOKIE_ACCEPT}
>
    <h3>{process.env.REACT_APP_COOKIE_H3}</h3>
    <p>{process.env.REACT_APP_COOKIE_TEXT}</p>
</CookieNotice>
				</nav>
			</>
		</BrowserRouter>
	);
}
/*
const UserPageWrapper = () => {
  const { slug } = useParams();
  useEffect(() => {
    console.log({ slug }); // <-- log param in effect
  }, [slug]);
  return <UserPage userName={slug} />;
};*/
function forceTrailingSlash(nextState, replace) {
  const path = nextState.location.pathname;
  if (path.slice(-1) !== '/') {
    replace({
      ...nextState.location,
      pathname: path + '/'
    });
  }
}
function forceTrailingSlashOnChange(prevState, nextState, replace) {
  forceTrailingSlash(nextState, replace);
}

function NotFound() {
    return (
	<>
	<Helmet>
		<title>404 | page not found</title>
		<link rel="canonical" to={window.location.protocol + "//" + window.location.host + "/404/"} />
	</Helmet>
	<Menutop />
        <div className="main">
            <h1>404 | page not found</h1>
        </div>
	</>
    )
}

const TitleinView = ({ title }) => {
	const options = {
		root: null,
		rootMargin: "0px",
		threshold: 1.0,
	};
	//const { ref, inView, entry } = useInView(options);
	return (
		<>
			<h2
				//ref={ref}
				/*className={"h1 " + (inView ? "active" : "")}*/
				className={"h1 " + ("active")}
				dangerouslySetInnerHTML={{ __html: title}}
			></h2>
		</>
	);
};

function Menutop({props}) {
	if (process.env.REACT_APP_POSTTYPE === 'posts') {
		return <>
			<div className={"site-header " +props}><nav className="site-header-i" itemScope itemType="http://schema.org/SiteNavigationElement">
			<Link className="nolink" to={"/"} >Blog</Link>
			<Link className="nolink" to={"/tag/epices/"} >Épices</Link>
			<Link className="nolink" to={"/tag/tisanes-et-thes/"} >Tisanes et Thés</Link>
			<Link className="nolink" to={"/tag/fruits-et-legumes/"} >Fruits et Légumes</Link>
			<Link className="nolink" to={"/tag/fitness/"} >Fitness</Link>
			</nav></div>
			</>
	} else if (process.env.REACT_APP_POSTTYPE === 'nutrition') {
		return <>
			<div className={"site-header " +props}><nav className="site-header-i" itemScope itemType="http://schema.org/SiteNavigationElement">
			<Link className="nolink" to={"/"} >Blog</Link>
			<Link className="nolink" to={"/tag/spices/"} >Spices</Link>
			<Link className="nolink" to={"/tag/herbal-teas/"} >Herbal teas and Teas</Link>
			<Link className="nolink" to={"/tag/fruits-and-vegetables/"} >Fruits and vegetables</Link>
			<Link className="nolink" to={"/tag/fitness/"} >Fitness</Link>
			</nav></div>
			</>
	} else {
		return <></>
	}
}




//const Home = lazy(() => import('./routes/Home'));
//const Tag = lazy(() => import('./routes/Tag'));
//const Post = lazy(() => import('./routes/Post'));
//function Home() {
//const Home = () => import('./routes/Home');



function Items({ posts, images }) {
  return (
    <>
      {posts &&
					posts.map((item) => (
					item.acf.featured === "" ? (
						<article key={item.id}>
							<Link className="nolink" to={"/blog/" + item.slug + "/"} >
								<TitleinView title={item.title.rendered}></TitleinView>
							</Link>
					{images && images.map((itemi) =>
					itemi.id == item.featured_media ? (
//<div key={item.link}></div>
					<img loading="lazy" key={itemi.id} src={itemi.guid.rendered}alt={item.title.rendered} />
					)
					:"")
					}
							
							<div
								className="content"
								dangerouslySetInnerHTML={{ __html: item.excerpt.rendered}}
							></div>
							<Link className="" to={"/blog/" + item.slug + "/"}>
								Lire
							</Link>
						</article>
					):("")
					))}
    </>
  );
}


function Mentions() {
if (process.env.REACT_APP_LANG_MAIN === 'en') {
	return <><Helmet>
		<meta name="robots" content="noindex" />
		</Helmet>
<div className="content main">
	<h1
		className="h1"
		dangerouslySetInnerHTML={{__html: "Legal notices and Privacy policy"}}
	></h1>
	<div className="opa1 fullw" >The site {window.location.protocol + "//" + window.location.host + "/"} is operated in compliance with French legislation. All data sent by users of our services is confidential. Only the web addresses or demos of the sites created will be mentioned publicly, with the right to withdraw.<br/><br/>
The site administrator reserves the right to modify the terms, conditions and warning notices in this document. Site Editor: M. Gael Ganlut, 8 rue Saint-Malo, 35000 Rennes – E-mail: <a href="mailto:design@ondevisuelle.fr">design@ondevisuelle.fr</a>, Tel: 06 59 20 57 69
– Website: {window.location.protocol + "//" + window.location.host + "/"}SIRET number: 518 303 417 00027 Editorial manager: Mr. Gael Ganlut, 8 rue Saint-Malo, 35000 Rennes – E-mail: <a href="mailto:design@ondevisuelle.fr">design@ondevisuelle.fr</a>, Tel: 06 59 20 57 69
– Website: {window.location.protocol + "//" + window.location.host + "/"}<br/><br/>

Accommodation:<br/><br/>

Host: Hetzner Online GmbH - Industriestr. 25 - 91710 Gunzenhausen - Germany

<br/><br/>

Development :<br/><br/>

Mr. Gael Ganlut, 8 rue Saint-Malo, 35000 Rennes – E-mail: <a href="mailto:design@ondevisuelle.fr">design@ondevisuelle.fr</a>, Tel: 06 59 20 57 69
– Website: {window.location.protocol + "//" + window.location.host + "/"}

<br/><br/>

Terms of use:<br/><br/>

This site ({window.location.protocol + "//" + window.location.host + "/"}) is available in different web languages (HTML, HTML5, Javascript, CSS, etc.) for greater user comfort. use and more pleasant graphics, we recommend that you use modern browsers such as Internet Explorer, Safari, Firefox, Google Chrome, etc... {window.location.protocol + "//" + window.location.host + " /"} implements all the means at its disposal to ensure reliable information and reliable updating of its websites. However, errors or omissions may occur. The Internet user must therefore ensure the accuracy of the information, and report any modifications to the site that he/she deems useful. {window.location.protocol + "//" + window.location.host + "/"} is in no way responsible for the use made of this information, and for any direct or indirect damage that may result therefrom.

<br/><br/>

Cookies :<br/><br/>

The site {window.location.protocol + "//" + window.location.host + "/"} may ask you to accept cookies for statistical and display purposes. A cookie is information placed on your hard drive by the server of the site you are visiting. It contains several pieces of data that are stored on your computer in a simple text file that a server accesses to read and save information. Certain parts of this site cannot be functional without accepting cookies.

<br/><br/>


Hypertext links:<br/><br/>

The websites of may offer links to other websites or other resources available on the Internet. {window.location.protocol + "//" + window.location.host + "/"} has no means of controlling the sites connected to its websites. does not answer for or guarantee the availability of such sites and external sources. It cannot be held responsible for any damage, of any nature whatsoever, resulting from the content of these sites or external sources, and in particular from the information, products or services they offer, or from any use that may be made of them. these elements. The risks associated with this use rest entirely with the Internet user, who must comply with their conditions of use. Users, subscribers and visitors to websites cannot set up a hyperlink to this site without the express prior authorization of {window.location.protocol + "//" + window.location.host + "/"}. In the event that a user or visitor wishes to set up a hyperlink to one of the websites of {window.location.protocol + "//" + window.location.host + "/"}, it will be up to them to send an email accessible on the site in order to formulate your request for the establishment of a hyperlink. {window.location.protocol + "//" + window.location.host + "/"} reserves the right to accept or refuse a hyperlink without having to justify its decision.

<br/><br/>

Services provided:<br/><br/>

all of the company&apos;s activities as well as its information are presented on our site {window.location.protocol + "//" + window.location.host + "/"}.

{window.location.protocol + "//" + window.location.host + "/"} strives to provide on the site {window.location.protocol + "//" + window.location.host + "/ "} information as accurate as possible. the information on the site {window.location.protocol + "//" + window.location.host + "/"} is not exhaustive and the photos are not contractual. They are given subject to modifications having been made since they were put online. Furthermore, all information indicated on the site {window.location.protocol + "//" + window.location.host + "/"} is given for information purposes only, and is subject to change or evolve without notice.

<br/><br/>


Contractual limitations on data:<br/><br/>

The information contained on this site is as accurate as possible and the site updated at different times of the year, but may contain inaccuracies or omissions. If you notice a gap, error or what appears to be a malfunction, please report it by email to the address design@ondevisuelle.fr, describing the problem as precisely as possible (page causing the problem, type of computer and browser used, etc.). Any content downloaded is done at the user&apos;s own risk and under their sole responsibility. Consequently, cannot be held responsible for any damage suffered by the user&apos;s computer or any loss of data following downloading. In addition, the user of the site undertakes to access the site using recent equipment, not containing viruses and with an updated latest generation browser. The hypertext links set up within the framework of this site internet in the direction of other resources present on the Internet network cannot engage the responsibility of {window.location.protocol + "//" + window.location.host + "/"}.

<br/><br/>


Intellectual property :<br/><br/>

All content present on the site {window.location.protocol + "//" + window.location.host + "/"}, including, without limitation, graphics, images, texts, videos, animations, sounds , logos, gifs and ShareButtons as well as their formatting are the exclusive property of the company with the exception of brands, logos or content belonging to other partner companies or authors. Any reproduction, distribution, modification, adaptation, retransmission or publication, even partial, of these various elements is strictly prohibited without the express written consent of {window.location.protocol + "//" + window.location.host + " /"}. This representation or reproduction, by any means whatsoever, constitutes an infringement punishable by articles L.335-2 et seq. of the Intellectual Property Code. Failure to comply with this prohibition constitutes an infringement which may result in civil and criminal liability for the infringer. In addition, the owners of the copied Content may take legal action against you.
The protection of the commercial name and the brand arises from the first public use, that is to say from their use (business papers, prospectuses, advertisements, invoices), and is preserved through use. The trade name &quot;Visual Wave&quot; has been protected since November 18, 2009.

<br/><br/>


Declaration to the CNIL:<br/><br/>

In accordance with law 78-17 of January 6, 1978 (amended by law 2004-801 of August 6, 2004 relating to the protection of individuals with regard to the processing of personal data) relating to computing, files and freedoms, this site has not been the subject of a declaration to the National Commission for Information Technology and Liberties (www.cnil.fr).

<br/><br/>

Disputes:<br/><br/>

These conditions of the site {window.location.protocol + "//" + window.location.host + "/"} are governed by French laws and any dispute or litigation which may arise from the interpretation or execution these will fall under the exclusive jurisdiction of the courts on which the company&apos;s head office depends. The reference language for settling any disputes is French.
your
<br/><br/>

Personal data :<br/><br/>

In general, you are not required to provide us with your personal data when you visit our website {window.location.protocol + "//" + window.location.host + "/"}. However, this principle has certain exceptions. Indeed, for certain services offered by our site, you may be required to communicate certain data to us such as: your name, your position, the name of your company, your email address, and your telephone number. This is the case when you complete the form offered to you online, in the &quot;contact&quot; section. In any case, you can refuse to provide your personal data. In this case, you will not be able to use the services of the site, in particular that of requesting information about our company, or receiving newsletters. Finally, we may automatically collect certain information about you during a simple navigation on our website, in particular: information concerning the use of our site, such as the areas you visit and the services you access, your address IP, your browser type, your access times. Such information is used exclusively for internal statistical purposes, in order to improve the quality of the services offered to you. The databases are protected by the provisions of the law of July 1, 1998 transposing directive 96/9 of March 11, 1996 relating to the legal protection of databases.
<br/><br/>

Affiliation:<br/><br/>

{window.location.protocol + "//" + window.location.host + "/"} is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide website owners with A means to earn advertising fees by advertising and linking to amazon.com, audible.com, and any other website that may be affiliated with the Amazon Service LLC Associates Program. As an Amazon Associate, we earn from qualifying purchases.

</div>
	</div>
</>;
} else {
return <><Helmet>
		<meta name="robots" content="noindex" />
		</Helmet>
<div className="content main">
	<h1
		className="h1"
		dangerouslySetInnerHTML={{__html: "Mentions légales et Politique de confidentialité"}}
	></h1>
	<div className="opa1 fullw" >Le site {window.location.protocol + "//" + window.location.host + "/"} est exploité dans le respect de la législation française. Toutes les données envoyées par les utilisateurs de nos services sont confidentielles. Seules les adresses web ou démos des sites créés seront mentionnées publiquement, avec droit de retrait.<br/><br/>
L&apos;administrateur du site se réserve le droit de modifier les termes, conditions et mentions d&apos;avertissement du présent document.Editeur du Site :M. Gael Ganlut, 8 rue Saint-Malo, 35000 Rennes – E-mail: <a href="mailto:design@ondevisuelle.fr">design@ondevisuelle.fr</a>, Tél: 06 59 20 57 69
– Site Web : {window.location.protocol + "//" + window.location.host + "/"}Numéro de SIRET : 518 303 417 00027Responsable editorial : M. Gael Ganlut, 8 rue Saint-Malo, 35000 Rennes – E-mail: <a href="mailto:design@ondevisuelle.fr">design@ondevisuelle.fr</a>, Tél: 06 59 20 57 69
– Site Web : {window.location.protocol + "//" + window.location.host + "/"}<br/><br/>

Hébergement :<br/><br/>

Hébergeur : Hetzner Online GmbH - Industriestr. 25 - 91710 Gunzenhausen - Germany

<br/><br/>

Développement :<br/><br/>

M. Gael Ganlut, 8 rue Saint-Malo, 35000 Rennes – E-mail: <a href="mailto:design@ondevisuelle.fr">design@ondevisuelle.fr</a>, Tél: 06 59 20 57 69
– Site Web : {window.location.protocol + "//" + window.location.host + "/"}

<br/><br/>

Conditions d&apos;utilisation :<br/><br/>

Ce site ({window.location.protocol + "//" + window.location.host + "/"} ) est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…) pour un meilleur confort d&apos;utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc... {window.location.protocol + "//" + window.location.host + "/"} met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. l&apos;internaute devra donc s&apos;assurer de l&apos;exactitude des informations, et signaler toutes modifications du site qu&apos;il jugerait utile. {window.location.protocol + "//" + window.location.host + "/"} n&apos;est en aucun cas responsable de l&apos;utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.

<br/><br/>

Cookies :<br/><br/>

Le site {window.location.protocol + "//" + window.location.host + "/"} peut-être amené à vous demander l&apos;acceptation des cookies pour des besoins de statistiques et d&apos;affichage. Un cookies est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations . Certaines parties de ce site ne peuvent être fonctionnelles sans l&apos;acceptation de cookies.

<br/><br/>

Liens hypertextes :<br/><br/>

Les sites internet de peuvent offrir des liens vers d&apos;autres sites internet ou d&apos;autres ressources disponibles sur Internet. {window.location.protocol + "//" + window.location.host + "/"} ne dispose d&apos;aucun moyen pour contrôler les sites en connexion avec ses sites internet. ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu&apos;ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l&apos;internaute, qui doit se conformer à leurs conditions d&apos;utilisation. Les utilisateurs, les abonnés et les visiteurs des sites internet de ne peuvent mettre en place un hyperlien en direction de ce site sans l&apos;autorisation expresse et préalable de {window.location.protocol + "//" + window.location.host + "/"}. Dans l&apos;hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d&apos;un des sites internet de {window.location.protocol + "//" + window.location.host + "/"}, il lui appartiendra d&apos;adresser un email accessible sur le site afin de formuler sa demande de mise en place d&apos;un hyperlien. {window.location.protocol + "//" + window.location.host + "/"} se réserve le droit d&apos;accepter ou de refuser un hyperlien sans avoir à en justifier sa décision.

<br/><br/>

Services fournis :<br/><br/>

l&apos;ensemble des activités de la société ainsi que ses informations sont présentés sur notre site {window.location.protocol + "//" + window.location.host + "/"}.

{window.location.protocol + "//" + window.location.host + "/"} s&apos;efforce de fournir sur le site {window.location.protocol + "//" + window.location.host + "/"} des informations aussi précises que possible. les renseignements figurant sur le site {window.location.protocol + "//" + window.location.host + "/"} ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site {window.location.protocol + "//" + window.location.host + "/"} sont données à titre indicatif, et sont susceptibles de changer ou d&apos;évoluer sans préavis.

<br/><br/>

Limitation contractuelles sur les données :<br/><br/>

Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l&apos;année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l&apos;adresse design@ondevisuelle.fr, en décrivant le problème de la manière la plus précise possible (page posant problème, type d&apos;ordinateur et de navigateur utilisé, …). Tout contenu téléchargé se fait aux risques et périls de l&apos;utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu responsable d&apos;un quelconque dommage subi par l&apos;ordinateur de l&apos;utilisateur ou d&apos;une quelconque perte de données consécutives au téléchargement. De plus, l&apos;utilisateur du site s&apos;engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour Les liens hypertextes mis en place dans le cadre du présent site internet en direction d&apos;autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de {window.location.protocol + "//" + window.location.host + "/"}.

<br/><br/>

Propriété intellectuelle :<br/><br/>

Tout le contenu du présent sur le site {window.location.protocol + "//" + window.location.host + "/"}, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l&apos;exception des marques, logos ou contenus appartenant à d&apos;autres sociétés partenaires ou auteurs. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l&apos;accord exprès par écrit de {window.location.protocol + "//" + window.location.host + "/"}. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.
La protection du nom commercial et de L&apos;enseigne naît du premier usage public, c&apos;est-à-dire de leur utilisation (papiers d&apos;affaires, prospectus, publicités, factures), et se conserve par L&apos;utilisation. Le nom commercial &quot;Onde visuelle&quot; est ainsi protégé depuis le 18 Novembre 2009.

<br/><br/>

Déclaration à la CNIL :<br/><br/>

Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l&apos;égard des traitements de données à caractère personnel) relative à l&apos;informatique, aux fichiers et aux libertés, ce site n&apos;a pas fait l&apos;objet d&apos;une déclaration auprès de la Commission nationale de l&apos;informatique et des libertés (www.cnil.fr).

<br/><br/>

Litiges :<br/><br/>

Les présentes conditions du site {window.location.protocol + "//" + window.location.host + "/"} sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l&apos;interprétation ou de l&apos;exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.
votre
<br/><br/>

Données personnelles :<br/><br/>

De manière générale, vous n&apos;êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet {window.location.protocol + "//" + window.location.host + "/"} . Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ». Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d&apos;information. Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d&apos;une simple navigation sur notre site Internet, notamment : des informations concernant l&apos;utilisation de notre site, comme les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d&apos;accès. De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
<br/><br/>

Affiliation :<br/><br/>

{window.location.protocol + "//" + window.location.host + "/"} participe au programme d&apos;associés d&apos;Amazon Services LLC, un programme de publicité d&apos;affiliation conçu pour fournir aux propriétaires de sites Web un moyen de gagner des frais de publicité en faisant de la publicité et en créant des liens vers amazon.com, audible.com et tout autre site Web susceptible de être affilié au programme d&apos;associés Amazon Service LLC. En tant qu&apos;associé Amazon, nous gagnons grâce aux achats éligibles.

</div>
	</div>
</>;
}

}
/*
function About() {
	return <h1 className="h1">About</h1>;
}

*/
/*

<SyntaxHighlighter language="javascript" style={docco}>
								{item.content.rendered}
							</SyntaxHighlighter>


  class PostComponent extends React.Component { 
    callback = (posts) => {
        // do something with value in parent component, like save to state
        console.log(posts);

    }

    render() {
      //const { slug } = useParams();
      //console.log(isLoaded);

        return (
          <div className="main">
          <h3>ID: </h3>
          <div >
  
          </div>
        </div>
        )
    }
}*/

/*

function AmazonPAAPI(props) {
	//const [data, setData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [postsAPI, setPostsAPI] = useState([]);
const [error, setError] = useState([]);

	useEffect(() => {
		//fetch("http://localhost:8080/api/posts/")
		fetch('https://webservices.amazon.com/paapi5/searchitems', {
    method: 'POST',
    headers: {
        'Host': 'webservices.amazon.com',
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Amz-Date': '20240129T145829Z',
        'X-Amz-Target': 'com.amazon.paapi5.v1.ProductAdvertisingAPIv1.SearchItems',
        'Content-Encoding': 'amz-1.0',
        'User-Agent': 'paapi-docs-curl/1.0.0',
        'Authorization': 'AWS4-HMAC-SHA256 Credential=AKIAIS6CH56RLEL7OT4Q/20240129/us-east-1/ProductAdvertisingAPI/aws4_request SignedHeaders=content-encoding;host;x-amz-date;x-amz-target Signature=69eff41ab66c8d121680114fef23ec649232c8e8e6794f9d5e35a30e4d6ae7ec'
    },
    body: JSON.stringify({
        'Marketplace': 'www.amazon.com',
        'PartnerType': 'Associates',
        'PartnerTag': 'parfumfemme-21',
        'Keywords': 'kindle',
        'SearchIndex': 'curcuma',
        'ItemCount': 3,
        'Resources': [
            'Images.Primary.Large',
            'ItemInfo.Title',
            'Offers.Listings.Price'
        ]
    })
})
			.then((res) => res.json())
			.then(
				(result) => {
					setIsLoaded(true);
					setPostsAPI(result);
console.log(result);
//console.log(result);
					//console.log(posts);
				},
				// Remarque : il faut gérer les erreurs ici plutôt que dans
				// un bloc catch() afin que nous n’avalions pas les exceptions
				// dues à de véritables bugs dans les composants.
			)
.catch(err => {console.log(err)})
				//(error) => {
				//	setIsLoaded(true);
				//	setError(error);
				//}
			//);
			//);
	}, []);

	return (
		<AmazonPAAPI value={postsAPI}>
			{props.children}
		</AmazonPAAPI>
	);
}*/


/*
function Page(params) {
	//export default class Post extends Component {
	// We can use the `useParams` hook here to access
	// the dynamic pieces of the URL.
	//const [posts, setPosts] = useState([]);

	//console.log(isLoaded);
	const { slug } = useParams();
	//console.log(slug);
	const [post, setPost] = useState([]);

	const posts = useContext(PagesContext);
	//console.log("posts");
	//console.log(posts);

	//console.log(slug);
	/*
	useEffect(() => {
		for (const item of posts) {
			// You can use `let` instead of `const` if you like
			if (item.id == slug) {
				setPost(item);
				console.log(item);
			}
		}
		//fetch("http://localhost:8080/api/posts/")
		// fetch("https://www.ondevisuelle.fr/old2/wp-json/wp/v2/posts")
		// 	.then((res) => res.json())
		// 	.then(
		// 		(result) => {
		// 			for (const item of result) {
		// 				if (item.id == slug) {
		// 					setPost(item);
		// 					console.log(item);
		// 				}
		// 			}
		// 		},
		// 		(error) => {
		// 			console.log(error);
		// 		}
		// 	);
	}, []);*/
	//console.log(params.myProp1);

	//console.log(props)
	//{item.title}
	//<img src={item.header} className="header" alt={item.title}/>
	//<div className="content" dangerouslySetInnerHTML={createBody(item)}>
	//</div> {post.title.rendered}
	// post.content.rendered*/
	/*return (
		<div className="main">
			{posts &&
				posts.map((item) =>
					item.slug == slug ? (
						<div key={item.id}>
							<h1
								className="h1"
								dangerouslySetInnerHTML={{ __html: item.title.rendered }}
							></h1>
							<div
								className="content"
								dangerouslySetInnerHTML={{ __html: item.acf.contenu}}
							></div>
						</div>
					) : (
						""
					)
				)}
		</div>
	);
}
*/
/*function Sitemap(params) {
	return (
}*/
/*
function createBody(item) {
	return {
		__html: item
			? item
					.replace(/<code>/g, "<pre><code>")
					.replace(/<\/code>/g, "</pre></code>")
			: item,
	};
}*/